<template>
  <div class="page add-payment-nicepay more-depth">
    <div class="container">
      <div class="page-header">
        <div class="page-header__title">
          <h1 class="text">
            {{ $__t('카드정보 입력') }}
          </h1>
        </div>
      </div>

      <div class="page-body">
        <PaymentNicePay :redirect="this.$route.query" />
      </div>
    </div>
  </div>
</template>

<script>
import PaymentNicePay from '@/v3/components/resources/payment/PaymentNicePay.vue';
export default {
  components: {
    PaymentNicePay
  },

  mounted() {
    setTimeout(() => {
      this.toggleHeaderTitleVisibility();
      window.addEventListener('scroll', this.toggleHeaderTitleVisibility);
    }, 0);
  },

  beforeDestroy() {
    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener('scroll', this.toggleHeaderTitleVisibility);
  },

  methods: {
    toggleHeaderTitleVisibility(clear, e) {
      const $pageHeaderTitle = $('.page-header > .page-header__title', this.$el);
      const $siteHeaderTitle = $('#site-header > .container > .header > .title');
      const $siteHeader = $('#site-header');

      if (clear === true) {
        $siteHeaderTitle.removeClass('off');
        return;
      }

      const isHeaderCovered = $pageHeaderTitle.offset().top < window.scrollY;

      if (!isHeaderCovered) {
        $siteHeaderTitle.addClass('off');
        $siteHeader.removeClass('shadow');
      } else {
        $siteHeaderTitle.removeClass('off');
        $siteHeader.addClass('shadow');
      }
    }
  }
};
</script>

<style lang="scss">
.page.add-payment-nicepay {
}
</style>
