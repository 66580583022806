<template>
  <div class="resource lg-text-input" :class="{ disabled: disabled }" :style="{ width: computedWidth }">
    <label>
      <div class="label">{{ label }}<span v-if="required" style="color: red"> * </span></div>
      <div class="textarea">
        <the-mask
          v-if="mask"
          :type="type"
          ref="input"
          :placeholder="placeholder"
          v-model="model"
          :disabled="disabled"
          autocapitalize="off"
          autocomplete="off"
          :mask="mask"
          :maxlength="maxlength"
          :inputmode="inputmode"
          :readonly="readonly"
          :class="{ certnumError: alert === true, readonly: readonly }"
        ></the-mask>
        <input
          v-else
          ref="input"
          :type="type"
          v-model="model"
          :placeholder="placeholder"
          :disabled="disabled"
          autocapitalize="off"
          autocomplete="off"
          :maxlength="maxlength"
          @focus="$emit('focus', $event)"
          :inputmode="inputmode"
          :readonly="readonly"
          :class="{ certnumError: alert === true, readonly: readonly }"
        />
      </div>
    </label>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';

export default {
  props: {
    value: {},
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String
    },
    label: {
      type: String
    },
    error: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    },
    maxlength: {
      type: Number
    },
    size: {
      type: String,
      default: 'block'
    },
    inputmode: {
      type: String,
      default: 'text'
    },
    mask: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    alert: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TheMask
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    computedWidth() {
      if (this.size == 'small') return '30%';
      else if (this.size == 'medium') return '65%';
      else return '100%';
    }
  },
  methods: {
    focus() {
      const input = this.$refs['input'];
      
      if (input.$el) {
        input.$el.focus();
      }
      else {
        input.focus();
      }
    }
  }
};
</script>

<style lang="scss">
.resource.lg-text-input {
  .label {
    // font-weight: 600;
    font-weight: 400;
    letter-spacing: normal;
    // font-size: unit(18);
    font-size: unit(12);
  }

  .textarea {
    resize: none;
    border-radius: $radius;

    & > input {
      padding: unit(6) 0;
      font-size: unit(14);
      line-height: 1.43;
      border-bottom: solid 1px #D9D9D9;
      transition: all 0.2s;
      &.certnumError {
        border-bottom: solid 1px red;
        color: red;
      }
      &.readonly {
        // color: #c2c2c2;
        border-bottom: solid 1px #c2c2c2 !important;
      }

      &:focus {
        border-bottom: solid 1px $color-primary-9;
        &.certnumError {
          border-bottom: solid 1px red;
          color: #1f1f1f;
        }
      }

      &::placeholder {
        color: #a9afb3;
      }
    }
  }

  &.disabled {
    .textarea {
      & > input {
        color: #c2c2c2 !important;
      }
    }
  }

  .error {
    font-size: $font-size-small;
    color: $color-red;
  }
}
</style>